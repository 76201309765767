import '@babel/polyfill'
import EventBus from './event-bus'
import AOS from 'aos'
import lazysizes from 'lazysizes'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faChevronLeft, faChevronRight, faChevronDown, faChevronUp, faPlus, faMinus, faSpinner } from '@fortawesome/pro-light-svg-icons'
import { faFacebookSquare, faInstagram, faPinterestSquare, faTwitterSquare  } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import stickybits from 'stickybits'
import freeform from './freeform'
import objectFitPolyfill from 'objectFitPolyfill'

library.add(faTimes, faChevronLeft, faChevronRight, faChevronDown, faChevronUp, faPlus, faMinus, faFacebookSquare, faInstagram, faPinterestSquare, faTwitterSquare, faSpinner)

// App main
const main = async () => {
    // Import our CSS
    const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.css');
    // Async load the vue module
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue')

    Vue.default.component('font-awesome-icon', FontAwesomeIcon)

    // Create our vue instance
    const vm = new Vue.default({
        el: "#app",
        components: {
            'mainNav': () => import(/* webpackChunkName: "nav" */ '../vue/MainNav.vue'),
            'accordion': () => import(/* webpackChunkName: "accordion" */ '../vue/Accordion.vue'),
            'flickity': () => import(/* webpackChunkName: "flickity" */ '../vue/Flickity.vue'),
            'productModal': () => import(/* webpackChunkName: "product-modal" */ '../vue/ProductModal.vue'),
            'productPreview': () => import(/* webpackChunkName: "product-preview" */ '../vue/ProductPreview.vue'),
            'serviceAccordion': () => import(/* webpackChunkName: "service-accordion" */ '../vue/ServiceAccordion.vue'),
            'lightbox': () => import(/* webpackChunkName: "lightbox" */ '../vue/Lightbox.vue'),
            'side-nav': () => import(/* webpackChunkName: "side-nav" */ '../vue/SideNav.vue'),
            'form-toggle': () => import(/* webpackChunkName: "form-toggle" */ '../vue/FormToggle.vue'),
        },
        data: {
            isProductModalVisible: false,
            isLightBoxVisible: false,
            openLightboxAtIndex: 0,
            activeProduct: {},
        },
        methods: {
            toggleMainNav() {
                EventBus.$emit('mainNav.toggled')
            },
            openProductModal(product) {
                EventBus.$emit('productModal.open', product)
            },
            openLightbox(openAtIndex) {
                EventBus.$emit('lightbox.open')
                this.openLightboxAtIndex = openAtIndex ? openAtIndex : 0
            },
            closeProductModal() {
                EventBus.$emit('productModal.close')
            }
        },
        mounted() {
            var self = this;

            AOS.init({
                duration: 1800,
                once: true
            });

            stickybits('.sticky', {useStickyClasses: true});

            EventBus.$on('productModal.open', function(product) {
                self.isProductModalVisible = true
                self.activeProduct = product
            })

            EventBus.$on('productModal.close', () => {
                this.isProductModalVisible = false
                this.activeProduct = {}
            })

            EventBus.$on('lightbox.open', function() {
                self.isLightBoxVisible = true
            })

            EventBus.$on('lightbox.close', function() {
                self.isLightBoxVisible = false
            })

            freeform()

        },
    });
};

const initMain = () => {
    // Execute async function
    main().then((value) => {
    })
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initMain); // Document still loading so DomContentLoaded can still fire :)
} else {
    initMain()
}
